import EuroIcon from '@mui/icons-material/Euro';
import HomeIcon from '@mui/icons-material/Home';
import { Button, Card, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { CardLarge, PageHeader } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import { fetchPerformance } from '../register/registerSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { deleteReservation, fetchMolliePayment, fetchTransaction, postPayment, putReservation, putTransaction, resetPaymentPost } from './mollieSlice';

export default function MollieReturnPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { performance, performanceStatus } = useSelector((state) => state.register);
    const { transaction, transactionStatus, molliePayment, molliePaymentStatus, updateTransactionStatus, paymentResult, paymentResultStatus } = useSelector((state) => state.mollie);

    const [hasUpdated, setHasUpdated] = useState(false);

    // fetch transaction from db
    useEffect(() => {
        dispatch(fetchTransaction(id));
    }, [id]);

    // fetch mollie payment from mollie
    useEffect(() => {
        if (transactionStatus === "success")
            dispatch(fetchMolliePayment(transaction.transactieId));
    }, [transactionStatus]);

    // update transaction in db with data from mollie
    useEffect(() => {
        if (molliePaymentStatus === "success" && !hasUpdated) {
            setHasUpdated(true);
            dispatch(putTransaction({
                idealKoppelingId: id,
                status: molliePayment.status,
                modifiedBy: `AP/${user?.number}`
            }));
        };
    }, [molliePaymentStatus]);

    // fetch transaction if update status changed
    useEffect(() => {
        if (updateTransactionStatus === "success") {
            dispatch(fetchTransaction(id));
        }
    }, [dispatch, id, updateTransactionStatus]);

    // confirm reservation if payment status is paid
    useEffect(() => {
        if (transaction?.status === "paid") {
            dispatch(putReservation({
                SubscriptionId: transaction?.inschrijvingId,
                PerformanceId: transaction?.onderwijsUitvoeringId,
                StudentId: user?.studentId,
                IsReservation: false,
                ModifiedBy: `AP/${user?.number}`,
            }));
        }
    }, [transaction]);

    // delete reservation if payment status is failed/canceled/expired and payment delay is disabled
    useEffect(() => {
        if ((transaction?.status === "failed" || transaction?.status === "canceled" || transaction?.status === "expired") && !siomSettings.mollieEnableDelay)
            dispatch(deleteReservation({
                SubscriptionId: transaction?.inschrijvingId,
                PerformanceId: transaction?.onderwijsUitvoeringId,
                StudentId: user?.studentId,
                IsReservation: true,
                ModifiedBy: `AP/${user?.number}`,
            }));
    }, [transaction]);

    // fetch performance info
    useEffect(() => {
        if (performanceStatus === null && transactionStatus === "success")
            dispatch(fetchPerformance({ performanceId: transaction?.onderwijsUitvoeringId }));
    }, [dispatch, performanceStatus, transactionStatus, transaction]);

    // Open mollie window if new payment is successfully made
    useEffect(() => {
        if (paymentResultStatus === "success") {
            window.open(paymentResult.links.checkout.href, "_self");
        } else if (paymentResultStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.PAYMENT_CREATION_ERROR}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPaymentPost());
        };
    }, [paymentResultStatus])

    const idealClick = () => dispatch(postPayment({
        StudentId: user?.studentId,
        PerformanceId: performance?.performanceId,
        DepartmentId: siomSettings?.useMultipleDepartments ? activeDepartment[0]?.id : null,
        CreatedBy: `AP/${user?.number}`,
        amount: performance?.cost,
        description: `${performance?.activity.name} - ${user?.number} - ${performance?.performanceNumber}`,
        redirectUrl: `${window.Config.apiUrl}inschrijven/betaling/`,
        reservationExists: true,
        reservationId: transaction?.inschrijvingId
    }));

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.PAYMENT} ${resourceFinal[appLanguage].words.RESULT.toLowerCase()}`} content={
            <ContentLoader successCondition={transactionStatus === "success" && molliePaymentStatus === "success"} errorCondition={transactionStatus === "failed" || molliePaymentStatus === "failed"} content={
                <>
                    <Card sx={{ ...CardLarge, flex: 0, p: 1 }}>
                        <Typography variant="h6" textAlign="center" sx={{ ...PageHeader }}>{performance?.activity.name}{performance?.performanceName && ` - ${performance?.performanceName}`}</Typography>
                        <Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].words.AMOUNT}: {Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(transaction?.bedrag)}</Typography>
                        <Typography variant="h6" textAlign="center">{resourceFinal[appLanguage].words.STATE}: {transaction?.status}</Typography>
                    </Card>
                    <Card sx={{ ...CardLarge, p: 1, mt: 1 }}>
                        {molliePayment?.status === "open" && <>
                            <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.PAYMENT_OPEN} {GetDate(molliePayment?.expiresAt)} {GetTime(molliePayment?.expiresAt)}.</Typography>
                            <Button variant="outlined" color="secondary" sx={{ width: 110, alignSelf: 'center', mt: 1 }} startIcon={<EuroIcon />} onClick={() => window.open(molliePayment?.links.checkout.href, "_self")}>
                                {resourceFinal[appLanguage].words.PAY}
                            </Button>
                        </>}
                        {molliePayment?.status === "paid" && <Typography variant="h6" textAlign="center">
                            {resourceFinal[appLanguage].messages.PAYMENT_PAID}
                        </Typography>}
                        {molliePayment?.status === "expired" && <Typography variant="h6" textAlign="center">
                            {resourceFinal[appLanguage].messages.PAYMENT_EXPIRED}
                        </Typography>}
                        {molliePayment?.status === "failed" && <Typography variant="h6" textAlign="center">
                            {resourceFinal[appLanguage].messages.PAYMENT_FAILED}
                        </Typography>}
                        {molliePayment?.status === "canceled" && <Typography variant="h6" textAlign="center">
                            {resourceFinal[appLanguage].messages.PAYMENT_CANCELED}
                        </Typography>}
                        {(molliePayment?.status !== "paid" && molliePayment?.status !== "open" && !siomSettings.mollieEnableDelay) && <Typography variant="h6" textAlign="center">
                            {resourceFinal[appLanguage].messages.PAYMENT_ACTION1}
                        </Typography>}
                        {(molliePayment?.status !== "paid" && molliePayment?.status !== "open" && siomSettings.mollieEnableDelay) && <>
                            <Typography variant="h6" textAlign="center">
                                {resourceFinal[appLanguage].messages.PAYMENT_ACTION2} {siomSettings?.mollieDelayHours} {resourceFinal[appLanguage].messages.PAYMENT_ACTION3}
                            </Typography>
                            <Button variant="outlined" color="secondary" sx={{ width: 110, alignSelf: 'center', mt: 1 }}
                                startIcon={<EuroIcon />} onClick={() => idealClick()}>
                                {resourceFinal[appLanguage].words.PAY}
                            </Button>
                        </>}
                        <Button variant="outlined" color="secondary" sx={{ width: 110, alignSelf: 'center', mt: 'auto' }}
                            startIcon={<HomeIcon />} onClick={() => navigate("/")}>
                            {resourceFinal[appLanguage].words.HOME}
                        </Button>
                    </Card>
                </>
            } />
        } bottomBar />
    );
}
